import React, {useState} from 'react';
import * as Sentry from '@sentry/nextjs';
import Api from 'library/api';
import firebase from 'firebase/compat/app';
// Components
import Button from '@brightlive/shared/components/Button';
import TextInput from '@brightlive/shared/components/TextInput';
import Divider from '@brightlive/shared/components/Divider';
import Checkbox from '@brightlive/shared/components/Checkbox';
// Images
import Google from 'public/images/google.svg';
// Redux
import {useDispatch, useSelector} from 'react-redux';
import {updateCurrentUser} from 'redux/auth/actions';
import {AuthReducer} from 'redux/auth/reducer';
// Helpers
import {validateEmail} from '@brightlive/shared/helpers/validators/isEmail';
import {validPassword} from 'library/helpers/validators/validPassword';
import {verifyToken} from 'library/auth';
import {
  LoadingAuthButton,
  AuthPages,
} from '@brightlive/shared/helpers/interfaces';
// Services
import {TrackingService} from 'bright-livekit/services/TrackingService';
// Styles
import S from '../style';

const TermsLabel = () => {
  return (
    <span>
      By creating an account, I acknowledge Bright’s{' '}
      <S.Link target="_blank" href="/privacy-policy" rel="noopener noreferrer">
        Privacy Policy
      </S.Link>{' '}
      and agree to its{' '}
      <S.Link
        target="_blank"
        href="/terms-of-service"
        rel="noopener noreferrer"
      >
        Terms of Service
      </S.Link>
      .
    </span>
  );
};

interface SignUpContentProps {
  setPage: (val: AuthPages) => void;
  continueUrl?: string | null;
  nameValue: string;
  setNameValue: (val: string) => void;
  emailValue: string;
  setEmailValue: (val: string) => void;
  emailError: string;
  setEmailError: (val: string) => void;
  passwordValue: string;
  setPasswordValue: (val: string) => void;
  agreedTerms: boolean;
  setAgreedTerms: (val: boolean) => void;
  disableEmailInput?: boolean;
  handleSocialLogin: (provider: 'google' | 'apple') => Promise<void>;
  message?: string;
}

const SignUpContent = ({
  setPage,
  continueUrl,
  nameValue,
  setNameValue,
  emailValue,
  emailError,
  setEmailError,
  setEmailValue,
  passwordValue,
  setPasswordValue,
  agreedTerms,
  setAgreedTerms,
  handleSocialLogin,
  disableEmailInput = false,
  message,
}: SignUpContentProps) => {
  const dispatch = useDispatch();

  const [nameError, setNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState<LoadingAuthButton>('');
  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';

  const onNameChange = e => {
    e.preventDefault();
    setNameValue(e.target.value);
    setNameError('');
  };

  const onEmailChange = async e => {
    e.preventDefault();
    setEmailValue(e.target.value);
    setEmailError('');
  };

  const onPasswordChange = e => {
    e.preventDefault();
    setPasswordValue(e.target.value);
  };

  const clearErrors = () => {
    setNameError('');
    setEmailError('');
    setPasswordError('');
  };

  const handleCreateUser = async () => {
    const data: Record<string, string | boolean> = {
      displayName: nameValue,
      email: emailValue,
      password: passwordValue,
      promotionalEmails: false,
      source: window?.sessionStorage.getItem('signupSource') ?? 'signup',
      sourceSession: window?.sessionStorage.getItem('sessionEntry') ?? '',
    };
    // send data to server to create user
    await Api.post('/users', data).execute(authToken);
  };

  const handleSignupClick = async () => {
    setLoading('email');
    clearErrors();
    let errors = false;
    // Validate that name has value
    if (!nameValue) {
      setNameError('Please enter a name');
      errors = true;
    }
    // Validate that email has value
    if (!validateEmail(emailValue)) {
      setEmailError('Please enter a valid email');
      errors = true;
    }
    // Check is email is unique
    if (validateEmail(emailValue)) {
      const response = await Api.get(
        `/users/unique-email?email=${encodeURIComponent(emailValue)}`
      ).execute();
      // Set error is email is taken
      if (!response.isUnique) {
        setEmailError('This email is already taken');
        errors = true;
      }
    }

    // Validate that password exists
    if (!validPassword(passwordValue)) {
      setPasswordError('Please enter a valid password');
      errors = true;
    }

    // If everything passes - go to next page
    if (!errors) {
      try {
        // Create user via server
        await handleCreateUser();
        // Send email verification email
        await Api.post('/users/verify-email', {
          value: emailValue,
          continueUrl,
        }).execute(authToken);
        // Sign in User
        const result = await firebase
          .auth()
          .signInWithEmailAndPassword(emailValue, passwordValue);
        const idToken = await result?.user?.getIdToken();
        const response = await verifyToken(idToken);
        if (response === 'authorized') {
          // Fetch user data with token
          const response = await Api.fetchCurrentUser(idToken);

          TrackingService.fire({
            event: 'registration',
            currentUser: response,
            data: {
              source: 'signup',
              session_id: window?.sessionStorage.getItem('sessionEntry'),
            },
          });
          // Set current user
          await dispatch(updateCurrentUser(response));
        }
      } catch (err) {
        Sentry.captureException(err);
        setLoading('');
        if (err instanceof Error) {
          setPasswordError(err?.message);
        }
      }
    }
    setLoading('');
  };

  return (
    <>
      <S.Title>{message ? message : 'Sign up'}</S.Title>
      <S.Input>
        <TextInput
          label="Name"
          onChange={onNameChange}
          value={nameValue}
          error={nameError}
        />
      </S.Input>
      <S.Input>
        <TextInput
          label="Email"
          onChange={onEmailChange}
          value={emailValue}
          error={emailError}
          disabled={disableEmailInput}
        />
      </S.Input>
      <S.Input>
        <TextInput
          label="Password"
          type="password"
          onChange={onPasswordChange}
          value={passwordValue}
          error={passwordError}
        />
      </S.Input>
      <S.Input>
        <Checkbox
          label={<TermsLabel />}
          onCheck={() => {
            setAgreedTerms(!agreedTerms);
          }}
          checked={agreedTerms}
        />
      </S.Input>
      <Button
        text="Sign up"
        type="primary"
        size="medium"
        width="100%"
        disabled={
          !emailValue ||
          !validPassword(passwordValue) ||
          !nameValue ||
          !agreedTerms
        }
        loading={loading === 'email'}
        onClick={handleSignupClick}
      />
      <S.Divider>
        <Divider />
        <S.Or>or</S.Or>
      </S.Divider>
      <S.GoogleButton>
        <Button
          text="Continue with Google"
          type="secondary"
          size="medium"
          width="100%"
          imgIcon={Google}
          loading={loading === 'google'}
          onClick={() => handleSocialLogin('google')}
        />
      </S.GoogleButton>
      <S.AppleButton>
        <Button
          text="Continue with Apple"
          type="secondary"
          size="medium"
          width="100%"
          icon="Apple"
          loading={loading === 'apple'}
          onClick={() => handleSocialLogin('apple')}
        />
      </S.AppleButton>
      <S.AltText>
        Already have an account?{' '}
        <S.Link
          onClick={() => {
            setPage('login');
          }}
        >
          Log in
        </S.Link>
      </S.AltText>
    </>
  );
};

export default SignUpContent;
