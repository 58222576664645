import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import firebase from 'firebase/compat/app';
import Api from 'library/api';
import {verifyToken} from 'library/auth';
import {destroyCookie} from 'nookies';
import {useRouter} from 'next/router';
// Components
import Modal from '@brightlive/shared/components/Modal';
import LoginContent from 'components/auth/LoginContent';
import SignUpContent from 'components/auth/SignUpContent';
import ForgotPasswordContent from 'components/auth/ForgotPasswordContent';
import AccountCreation from 'components/auth/AccountCreation';
import ForgotPasswordSentContent from 'components/auth/ForgotPasswordSentContent';
import SignUpContentAbout from 'components/auth/SignUpContentAbout';
import {Col, Grid, Row} from '@brightlive/shared/components/Grid';
import NavigationBar from 'components/shared/Navigation/NavigationBar';
import Breadcrumb from '@brightlive/shared/components/Breadcrumb';
import {ExistingGuestContent, GuestLoginContent} from 'bright-livekit';
// Images
import LoginBackground from 'public/images/login-background.svg';
import SignupBackground from 'public/images/signup-background.svg';
// Redux
import {UIReducer} from 'redux/ui/reducer';
import {AuthReducer} from 'redux/auth/reducer';
import {setAuthToken, setContinueUrl} from 'redux/auth/actions';
import {
  toggleLoginModal,
  setLoginModalPage,
  setLoginClosable,
  setLoginModalEmail,
} from 'redux/ui/actions';
import {updateCurrentUser} from 'redux/auth/actions';
// Helpers
import {validateEmail} from '@brightlive/shared/helpers/validators/isEmail';
import {BranchEvent} from '@brightlive/shared/helpers/enums';
import {branchInitIdentity, branchTrackEvent} from 'library/helpers/branch';
import {getAuthErrorMessage} from '@brightlive/shared/helpers/auth';
import {
  LoadingAuthButton,
  AuthPages,
} from '@brightlive/shared/helpers/interfaces';
import {useWindowSize} from '@brightlive/shared/hooks/useWindowSize';
// Style
import S from './style';
import {TrackingService} from 'bright-livekit/services/TrackingService';

declare global {
  interface Window {
    recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  }
}

const LoginModal = () => {
  const dispatch = useDispatch();
  const {height} = useWindowSize();
  const router = useRouter();
  // Selectors
  const loginClosable = useSelector(
    (state: UIReducer) => state.ui.loginClosable
  );
  const loginModalVisible = useSelector(
    (state: UIReducer) => state.ui.loginModalVisible
  );
  const loginModalPage = useSelector(
    (state: UIReducer) => state.ui.loginModalPage
  );
  const loginModalEmail = useSelector(
    (state: UIReducer) => state.ui.loginModalEmail
  );
  const loginModalMessage = useSelector(
    (state: UIReducer) => state.ui.loginModalMessage
  );
  const continueUrl = useSelector(
    (state: AuthReducer) => state.auth.auth?.continueUrl
  );
  const authToken =
    useSelector((state: AuthReducer) => state.auth.auth.authToken) || '';
  // States
  const [initialEmailValue, setInitialEmailValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [nameError, setNameError] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [resetPasswordValue, setResetPasswordValue] = useState('');
  const [resetPasswordError, setResetPasswordError] = useState('');
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [page, setPage] = useState<AuthPages>(loginModalPage);
  const [previousForgotPage, setPreviousForgotPage] = useState<AuthPages>(
    'existing-guest-password'
  );
  const [message, setMessage] = useState(loginModalMessage);
  const [loading, setLoading] = useState<LoadingAuthButton>('');

  const contentRef = useRef<HTMLDivElement>(null);

  const resetRecaptch = async () => {
    // reset the reCAPTCHA so the user can try signing in again
    const widgetId = await window.recaptchaVerifier.render();
    return grecaptcha.reset(widgetId);
  };

  useEffect(() => {
    // Setup recaptcha for phone login
    if (loginModalVisible && !window?.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'grecaptcha-button',
        {
          size: 'invisible',
        }
      );
      // Pre-render reCaptcha
      window.recaptchaVerifier.render();
    } else if (window?.recaptchaVerifier) {
      // reset the reCAPTCHA so the user can try signing in again
      resetRecaptch();
    }
    // Reset page if modal is closed
    if (!loginModalVisible) {
      setPage('login');
      dispatch(setLoginModalPage('login'));
    }
  }, [loginModalVisible]);

  // If starting flow over - reset values
  useEffect(() => {
    if (page === 'login' || page === 'signup') {
      setNameValue('');
      setEmailValue(initialEmailValue);
      setPasswordValue('');
    }
    if (page !== 'forgot-password') {
      setPreviousForgotPage(page);
    }
  }, [page]);
  // Set page if it updates in reducer
  useEffect(() => {
    setPage(loginModalPage);
  }, [loginModalPage]);

  useEffect(() => {
    setMessage(loginModalMessage);
  }, [loginModalMessage]);

  const handleAnonLogin = async (skipEmail = false) => {
    setLoading('join');
    const userEmail = skipEmail === true ? '' : emailValue;
    try {
      if (userEmail) {
        // Check is email is unique
        const response = await Api.get(
          `/users/unique-email?email=${encodeURIComponent(userEmail)}`
        ).execute();
        // Set error is email is taken
        if (!response.isUnique) {
          if (response.providers?.includes('password')) {
            setInitialEmailValue(userEmail);
            setPage('existing-guest-password');
            await dispatch(setLoginModalPage('existing-guest-password'));
            setLoading('');
            return;
          } else if (response.providers?.length > 0) {
            setEmailError(
              'The account uses social login. Please use one of the social login options below'
            );
            setLoading('');
            return;
          } else {
            setEmailError('This email is already taken');
            setLoading('');
            return;
          }
        } else if (page !== 'finish-creation') {
          setLoading('');
          setPage('finish-creation');
          await dispatch(setLoginModalPage('finish-creation'));
          return;
        }
      }
      const result = await firebase.auth().signInAnonymously();

      const idToken = await result?.user?.getIdToken();
      const response = await verifyToken(idToken);
      if (response === 'authorized') {
        dispatch(setAuthToken(idToken));
        // Set current user
        const userData = await Api.loginAnonUser(
          idToken,
          nameValue,
          userEmail,
          window?.sessionStorage.getItem('signupSource') ?? 'signup',
          window?.sessionStorage.getItem('sessionEntry') ?? ''
        );
        if (emailValue && userEmail) {
          result.user?.updateEmail(emailValue);
        }

        TrackingService.fire({
          event: 'registration',
          currentUserId: userData.uid,
          roles: ['anon'],
          data: {
            session_id: window?.sessionStorage.getItem('sessionEntry'),
            source: window?.sessionStorage.getItem('signupSource') ?? 'signup',
          },
        });
        // Set current user
        await dispatch(updateCurrentUser(userData));
        // Close Modal
        closeModal();
        setLoading('');
        success(userData?.id);
      } else {
        setEmailError('Unauthorized');
        destroyCookie(null, '__session');
        destroyCookie(null, 'cssc');
        setLoading('');
      }
    } catch (error: unknown) {
      console.log(error);
      const {code, message} = error as firebase.auth.Error;
      setEmailError(getAuthErrorMessage(code, message));
      setLoading('');
    }
  };

  const handleSocialLogin = async (provider: 'google' | 'apple') => {
    setLoading(provider);
    const anonUserId = firebase.auth().currentUser?.isAnonymous
      ? firebase.auth().currentUser?.uid
      : undefined;
    try {
      let providerInstance;
      if (provider === 'apple') {
        const appleInstance = new firebase.auth.OAuthProvider('apple.com');
        appleInstance.addScope('email');
        appleInstance.addScope('name');
        providerInstance = appleInstance;
      } else {
        providerInstance = new firebase.auth.GoogleAuthProvider();
      }
      const result = await firebase.auth().signInWithPopup(providerInstance);
      if (anonUserId && anonUserId !== result.user?.uid) {
        window?.location.reload();
        return;
      }
      const idToken = await result?.user?.getIdToken();
      const response = await verifyToken(idToken);
      let authEvent = 'login';
      let source = '';
      if (response === 'authorized') {
        dispatch(setAuthToken(idToken));
        // Set current user
        let userData;
        try {
          userData = await Api.fetchCurrentUser(idToken);
        } catch (err) {
          if (result.user && result.user.email) {
            const data: Record<string, string | boolean> = {
              firstName: result.user.displayName || '',
              lastName: result.user.displayName || '',
              displayName: result.user.displayName || 'Anonymous',
              email: result.user.email,
              source:
                window?.sessionStorage.getItem('signupSource') ?? 'signup',
              sourceSession:
                window?.sessionStorage.getItem('sessionEntry') ?? '',
            };
            // send data to server to create user
            await Api.post('/users/social', data).execute(idToken);
            authEvent = 'registration';
            source = window?.sessionStorage.getItem('signupSource') ?? 'signup';
            userData = await Api.fetchCurrentUser(idToken);
          } else {
            throw err;
          }
        }

        TrackingService.fire({
          event: authEvent,
          currentUser: userData,
          data: {
            session_id: window?.sessionStorage.getItem('sessionEntry'),
            source,
          },
        });
        // Set current user
        await dispatch(updateCurrentUser(userData));
        // Close Modal
        closeModal();
        setLoading('');
        success(userData?.id);
      } else {
        setEmailError('Unauthorized');
        destroyCookie(null, '__session');
        destroyCookie(null, 'cssc');
        setLoading('');
      }
    } catch (error: unknown) {
      console.error(error);
      const {code, message} = error as firebase.auth.Error;
      setEmailError(getAuthErrorMessage(code, message));
      setLoading('');
    }
  };

  // Handle Email Login
  const handleEmailLogin = async () => {
    setLoading('email');
    const anonUserId = firebase.auth().currentUser?.isAnonymous
      ? firebase.auth().currentUser?.uid
      : undefined;
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(emailValue, passwordValue);
      if (anonUserId && anonUserId !== result.user?.uid) {
        window?.location.reload();
        return;
      }
      const idToken = await result?.user?.getIdToken();
      const response = await verifyToken(idToken);
      if (response === 'authorized') {
        dispatch(setAuthToken(idToken));
        // Set current user
        const userData = await Api.fetchCurrentUser(idToken);

        TrackingService.fire({
          event: 'login',
          currentUser: userData,
          data: {
            session_id: window?.sessionStorage.getItem('sessionEntry'),
          },
        });
        // Set current user
        await dispatch(updateCurrentUser(userData));
        // Close Modal
        closeModal();
        setLoading('');
        success(userData?.id);
      } else {
        setEmailError('Unauthorized');
        destroyCookie(null, '__session');
        destroyCookie(null, 'cssc');
        setLoading('');
      }
    } catch (error: unknown) {
      const {code, message} = error as firebase.auth.Error;
      setEmailError(getAuthErrorMessage(code, message));
      setLoading('');
    }
  };
  const handleResetPassword = async () => {
    setLoading('reset-password');
    // Check for validation errors
    if (!validateEmail(resetPasswordValue)) {
      setResetPasswordError('Please enter a valid email');
      setLoading('');
      return;
    }
    try {
      const value = resetPasswordValue;
      // Dispatch
      await Api.post('/users/reset-password', {type: 'email', value}).execute(
        authToken
      );
      // Set page to the second
      setPage('forgot-password-sent');
      setLoading('');
    } catch (error: unknown) {
      const {code, message} = error as firebase.auth.Error;
      setEmailError(getAuthErrorMessage(code, message));
      setLoading('');
      return;
    }
  };
  const getContent = () => {
    if (page === 'signup') {
      return (
        <SignUpContent
          message={message}
          setPage={setPage}
          continueUrl={continueUrl}
          nameValue={nameValue}
          setNameValue={setNameValue}
          emailValue={
            loginModalEmail && typeof loginModalEmail === 'string'
              ? loginModalEmail
              : emailValue
          }
          disableEmailInput={
            !!(loginModalEmail && typeof loginModalEmail === 'string')
          }
          emailError={emailError}
          setEmailError={setEmailError}
          setEmailValue={setEmailValue}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          agreedTerms={agreedTerms}
          setAgreedTerms={setAgreedTerms}
          handleSocialLogin={handleSocialLogin}
        />
      );
    } else if (page === 'forgot-password') {
      return (
        <ForgotPasswordContent
          loading={loading}
          handleResetPassword={handleResetPassword}
          resetPasswordError={resetPasswordError}
          resetPasswordValue={resetPasswordValue}
          setResetPasswordValue={setResetPasswordValue}
          setResetPasswordError={setResetPasswordError}
        />
      );
    } else if (page === 'forgot-password-sent') {
      return (
        <ForgotPasswordSentContent
          resetPasswordValue={resetPasswordValue}
          handleResetPassword={handleResetPassword}
        />
      );
    } else if (page === 'existing-guest-password') {
      return (
        <ExistingGuestContent
          emailValue={emailValue}
          passwordValue={passwordValue}
          emailError={emailError}
          setPasswordValue={setPasswordValue}
          setEmailError={setEmailError}
          handleEmailLogin={handleEmailLogin}
          setPage={setPage}
          loading={loading}
        />
      );
    } else if (page === 'finish-creation') {
      return (
        <AccountCreation
          nameValue={nameValue}
          emailValue={emailValue}
          emailError={emailError}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          setEmailError={setEmailError}
          handleEmailLogin={handleEmailLogin}
          handleAnonLogin={handleAnonLogin}
          parentLoading={loading}
        />
      );
    } else if (page === 'anon') {
      return (
        <GuestLoginContent
          loading={loading}
          setPage={setPage}
          nameValue={nameValue}
          setNameValue={setNameValue}
          nameError={nameError}
          setNameError={setNameError}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          emailError={emailError}
          setEmailError={setEmailError}
          handleAnonLogin={handleAnonLogin}
          handleSocialLogin={handleSocialLogin}
        />
      );
    } else {
      return (
        <LoginContent
          loading={loading}
          setPage={setPage}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          emailError={emailError}
          setEmailError={setEmailError}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          handleEmailLogin={handleEmailLogin}
          handleSocialLogin={handleSocialLogin}
        />
      );
    }
  };

  const handleBack = () => {
    if (page === 'forgot-password') {
      setPage(previousForgotPage);
    } else if (page === 'existing-guest-password') {
      setPage('anon');
    }
    return;
  };

  const success = (userId: string) => {
    if (userId !== '') {
      branchInitIdentity(userId);
    }
    branchTrackEvent(
      BranchEvent.LOGIN,
      {
        user_id: userId,
      },
      {},
      'login'
    );
    if (continueUrl) {
      router.push(continueUrl);
    }
  };
  const closeModal = (toggleModal = true) => {
    setEmailValue('');
    setPasswordValue('');
    setResetPasswordValue('');
    setResetPasswordError('');
    setEmailError('');
    setPage('login');
    dispatch(setLoginClosable(true));
    dispatch(setContinueUrl(null));
    dispatch(setLoginModalEmail(''));
    if (toggleModal) dispatch(toggleLoginModal(false));
  };

  const getPageLayout = () => {
    if (
      page === 'forgot-password' ||
      page === 'forgot-password-sent' ||
      page === 'anon' ||
      page === 'existing-guest-password' ||
      page === 'finish-creation'
    ) {
      return (
        <Row align="center">
          <Col md={4}>
            <S.CenteredContent>{getContent()}</S.CenteredContent>
          </Col>
        </Row>
      );
    } else if (page === 'login' || page === 'signup') {
      return (
        <Row>
          <Col md={4} mdOffset={1}>
            <S.Content ref={contentRef}>
              <S.LeftContentInner>{getContent()}</S.LeftContentInner>
            </S.Content>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const scrollToForm = () => {
    if (contentRef?.current) {
      contentRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const getBackground = () => {
    if (page === 'login') {
      return (
        <S.BackgroundWrapper $page="login">
          <S.Background src={LoginBackground} />
        </S.BackgroundWrapper>
      );
    } else if (page === 'signup') {
      return (
        <S.BackgroundWrapper $page="signup">
          <S.Background src={SignupBackground} />
          <SignUpContentAbout scrollToForm={scrollToForm} />
        </S.BackgroundWrapper>
      );
    }
    return;
  };

  const backIsVisible =
    page === 'existing-guest-password' || page === 'forgot-password';

  const isAuthScreenNav = router.pathname === '/login';

  if (loginModalVisible) {
    if (!loginClosable) {
      return (
        <>
          <NavigationBar
            liveSession={!isAuthScreenNav}
            isAuthScreen={isAuthScreenNav}
          />
          <S.Page $pageHeight={height || 0} $isSignup={page === 'signup'}>
            <Grid>
              <>
                {backIsVisible && (
                  <Row>
                    <Col>
                      <S.Breadcrumb>
                        <Breadcrumb text="Back" onClick={handleBack} />
                      </S.Breadcrumb>
                    </Col>
                  </Row>
                )}
                {getPageLayout()}
              </>
            </Grid>
            {getBackground()}
          </S.Page>
        </>
      );
    }
    return (
      <Modal
        closeModal={loginClosable ? closeModal : undefined}
        mobileHeight="100%"
        backVisible={backIsVisible}
        handleBack={handleBack}
      >
        <S.ModalContent>{getContent()}</S.ModalContent>
      </Modal>
    );
  }
  return null;
};

export default React.memo(LoginModal);
